export enum ROUTES {
	LOGIN = '/login',
	REGISTER = '/register',
	FORGOT = '/forgot',
	CONFIRM = '/confirm',
	HOME = '/home',
	ROOT = '/',
	CERTIFICATE_BUILDER = '/certificate-builder',
	CERTIFICATE = '/certificate',
}
