import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

interface CerProps {
	CertiData: {
		candidateName: string;
		duration: string;
		position: string;
		startDate: string;
		endDate: string;
		signedBy: string;
		signedByWorkTitle: string;
		pronoun: string;
	};
}
const CertificateDwn = ({ CertiData }: CerProps) => {
	return (
		<Box minHeight='100vh'>
			<Grid height='100vh' container direction={{ xs: 'row' }}>
				<Grid
					item
					md={3}
					xs={12}
					sx={{ background: '#1D1263' }}
					height={{ md: '100%', xs: '30%' }}
					display='flex'
					flexDirection={{ md: 'column' }}
					justifyContent='space-between'
					padding={{ md: '80px 0 50px', sm: '0px 70px', xs: '30px' }}
				>
					<Box
						display='flex'
						flexDirection='column'
						gap={1.5}
						justifyContent='center'
						marginLeft={{ md: '0', xs: '10px' }}
					>
						<Box display='flex' width='100%' height={{ md: '80px', sm: '70px', xs: '50px' }}>
							<img
								style={{ margin: '0 auto', height: '100%' }}
								src='/assets/images/logo.svg'
								alt=''
							/>
						</Box>
						<Box
							fontSize={{ md: '35px', sm: '30px', xs: '25px' }}
							lineHeight='47px'
							sx={{
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '500',
								textAlign: 'center',
								color: '#E6E6FD',
							}}
							className='bold'
						>
							SimpleM
						</Box>
					</Box>
					<Box
						fontSize={{ md: '16px', sm: '13.5px', xs: '10px' }}
						fontWeight='400'
						lineHeight={{ md: '22px', sm: '18px', xs: '16px' }}
						sx={{
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							color: '#F9F9FF',
						}}
						padding={{ md: '0 40px', xs: '0px' }}
						display='flex'
					>
						<Box margin='auto 0'>
							<Box className='extra_bold'>Contact Us :</Box>
							<Box height={{ md: 25, xs: 15 }} />
							<Box display='flex' gap={{ md: 3, xs: 1.5 }}>
								<Box>
									<img className='img_lg' src='/assets/images/Vector_2.svg' alt='' />
								</Box>
								<Box>
									Plot number 15, Block T, KP2,
									<br /> Jaypee Wishtown,
									<br /> Sector 131, Noida, UP 201304
								</Box>
							</Box>
							<Box height={{ md: 15, xs: 8 }} />
							<Box display='flex' gap={{ md: 3, xs: 1.5 }}>
								<Box>
									<img className='img_lg' src='/assets/images/Vector_0.svg' alt='' />
								</Box>
								<Box>reach@simplem.in</Box>
							</Box>
							<Box height={{ md: 15, xs: 8 }} />
							<Box display='flex' gap={{ md: 3, xs: 1.5 }}>
								<Box>
									<img className='img_lg' src='/assets/images/Vector_1.svg' alt='' />
								</Box>
								<a href='https://simplem.in'>
									<Box>
										<Typography color={'white'} style={{ textDecoration: 'none' }}>
											www.simplem.in
										</Typography>{' '}
									</Box>
								</a>
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					height={{ md: '100%', xs: '70%' }}
					md={9}
					xs={12}
					className='content_section'
					padding={{ md: '0 70px', xs: '0 35px' }}
					position='relative'
					sx={{ '@media (max-width: 600px)': { backgroundSize: 'cover' } }}
					display='flex'
					flexDirection='column'
					justifyContent='space-evenly'
				>
					<Box position='absolute' top='0' left={{ md: '88%', xs: '85%' }} className='frame'>
						<img src='/assets/images/Frame.svg' alt='' />
					</Box>
					<Box>
						<Box
							fontSize={{ md: '50px', sm: '35px', xs: '25px' }}
							lineHeight={{ md: '60px', sm: '40px', xs: '32px' }}
							sx={{
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								color: '#1D1263',
								fontWeight: '500',
							}}
							className='bold'
						>
							CERTIFICATE OF <br />
							<Box
								component='span'
								fontSize={{ md: '60px', sm: '42px', xs: '32px' }}
								lineHeight={{ md: '70px', sm: '50px', xs: '42px' }}
							>
								COMPLETION
							</Box>
						</Box>
					</Box>
					<Box
						fontSize={{ md: '25px', sm: '20px', xs: '16px' }}
						lineHeight={{ md: '30px', sm: '25px', xs: '20px' }}
						sx={{
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: '300',
							color: '#000000',
						}}
						width='88%'
						className='content'
					>
						<Box component='span'>
							This is to ceritify that <br />
						</Box>
						<Box height={10} />
						<Box
							component='span'
							fontFamily='Allura'
							fontSize={{ md: '55px', sm: '40px', xs: '30px' }}
							lineHeight={{ md: '77.5px', sm: '50px', xs: '40px' }}
							position='relative'
							className='name'
						>
							{CertiData.candidateName ? CertiData.candidateName : 'Someone New'} <br />
						</Box>
						<Box height={10} />
						<Box component='span' lineHeight='30px'>
							has successfully completed {CertiData.pronoun ? CertiData.pronoun : 'her'}{' '}
							<span style={{ fontWeight: '500' }} className='bold'>
								{CertiData.duration ? CertiData.duration : '2'} months
							</span>{' '}
							internship in{' '}
							<span style={{ fontWeight: '500' }} className='bold'>
								Simplem Solutions Private Limited
							</span>{' '}
							in field of{' '}
							<span style={{ fontWeight: '500' }} className='bold'>
								{CertiData.position ? CertiData.position : 'UI/UX Design'}
							</span>
							.<br />
						</Box>
						<Box height={30} />
						<Box component='span'>
							Duration :{' '}
							<span style={{ fontWeight: '500' }} className='bold'>
								{CertiData.startDate ? CertiData.startDate : '20 July 2022'} to{' '}
								{CertiData.endDate ? CertiData.endDate : '20 Sep 2022'}
							</span>
						</Box>
					</Box>
					<Box display='flex' flexDirection='column' alignItems='flex-end'>
						<Box
							minWidth={{ md: '330px', xs: '230px' }}
							fontSize={{ md: '25px', sm: '21px', xs: '18px' }}
							lineHeight={{ md: '35px', sm: '31px', xs: '28px' }}
							sx={{
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '300',
								color: '#000000',
							}}
						>
							<Box textAlign='center' fontWeight='500' className='bold'>
								<>
									<Box>
										<img height={80} src='/assets/images/sign.png' alt='' />
									</Box>
									<Box>Prashant Choudhary</Box>
								</>
								{/* {CertiData.signedBy ? (
									CertiData.signedBy
								) : (
									<>
										<Box>
											<img height={80} src='/assets/images/sign.png' alt='' />
										</Box>
										<Box>Prashant Choudhary</Box>
									</>
								)} */}
							</Box>
							<Box height='0.8px' width='100%' sx={{ background: '#000000' }} />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CertificateDwn;
