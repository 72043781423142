import React, { useState, useEffect, useRef } from 'react';
import CertificateDwn from '../elements/CertificateDwn';
import '../styles/certificate.scss';
import { Loading } from '../elements/Loading';
import { useParams } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import axios from 'axios';
import { PDFExport } from '@progress/kendo-react-pdf/dist/npm/PDFExport';

const Certificate = () => {
	const [certiData, setCertiData] = useState({
		candidateName: '',
		duration: '',
		position: '',
		startDate: '',
		endDate: '',
		signedBy: '',
		signedByWorkTitle: '',
		pronoun: '',
	});

	const [loading, setLoading] = useState(true);
	const [isValid, setIsValid] = useState(false);
	const { certificateId } = useParams();

	const pdfExportComponent = useRef<PDFExport>(null);

	const handleExportWithComponent = () => {
		pdfExportComponent.current?.save();
	};

	useEffect(() => {
		axios
			.get(`/api/certificate/${certificateId}`)
			.then(res => {
				if (res.data) {
					const data = res.data;
					data.startDate = Intl.DateTimeFormat('en-GB', {
						day: '2-digit',
						month: 'short',
						year: 'numeric',
					}).format(Date.parse(data.startDate));
					data.endDate = Intl.DateTimeFormat('en-GB', {
						day: '2-digit',
						month: 'short',
						year: 'numeric',
					}).format(Date.parse(data.endDate));
					setCertiData(res.data);
					setIsValid(true);
				} else {
				}
				setLoading(false);
			})
			.catch(err => setLoading(false));
	}, []);

	return loading ? (
		<Loading />
	) : isValid ? (
		<>
			<PDFExport ref={pdfExportComponent} paperSize='auto'>
				<CertificateDwn CertiData={certiData} />{' '}
			</PDFExport>
			<div style={{ textAlign: 'center' }}>
				<Button style={{ width: '25%' }} onClick={handleExportWithComponent}>
					Download
				</Button>
			</div>
		</>
	) : (
		<Container style={{ paddingTop: '10vh' }}>Invalid Certificate</Container>
	);
};

export default Certificate;
